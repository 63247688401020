import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page/default/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Paper className="clear-top clear-bottom" mdxType="Paper">
      <Text className="compact-top heading" variant="h3" mdxType="Text">
  Introduction
      </Text>
      <p>{`Remote Creative Work ("Us" or "We") provides the RemoteCreativeWork.com website and various related services (collectively, the "Website") to you, the User, subject to your compliance with all the terms, conditions, and notices contained or referenced herein (the "Terms of Service"), as well as any other written agreement between us and you.`}</p>
      <p>{`In addition, when using particular services or materials on this Website, Users shall be subject to any posted rules applicable to such services or materials that may contain terms and conditions in addition to those in these Terms of Service. All such guidelines or rules are hereby incorporated by reference into these Terms of Service.`}</p>
      <p>{`These Terms of Service are effective as of 08/18/2020. We expressly reserve the right to change these Terms of Service from time to time without notice to you. You acknowledge and agree that it is your responsibility to review this Website and these Terms of Service from time to time and to familiarize yourself with any modifications.`}</p>
      <p>{`Your continued use of this Website after such modifications will constitute acknowledgement of the modified Terms of Service and agreement to abide and be bound by the modified Terms of Service.`}</p>
      <p>{`We reserve the sole right to either modify or discontinue the Website, including any of the Website’s features, at any time with or without notice to you. We will not be liable to you or any third party should we exercise such right. Any new features that augment or enhance the then-current services on this Website shall also be subject to these Terms of Service.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Purchases
      </Text>
      <p>{`If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to provide information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.`}</p>
      <p>{`You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.`}</p>
      <p>{`We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.`}</p>
      <p>{`We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.`}</p>
      <p>{`We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.`}</p>
      <Text className="heading" variant="h4" mdxType="Text">
  Subscriptions
      </Text>
      <p>{`Some parts of Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.`}</p>
      <p>{`At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Blush Design Inc cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Blush Design Inc customer support team.`}</p>
      <p>{`A valid payment method, including credit card or PayPal, is required to process the payment for your subscription. You shall provide Blush Design Inc with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Blush Design Inc to charge all Subscription fees incurred through your account to any such payment instruments.`}</p>
      <p>{`Should automatic billing fail to occur for any reason, Blush Design Inc will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.`}</p>
      <Text className="heading" variant="h4" mdxType="Text">
  Fee Changes
      </Text>
      <p>{`Remote Creative Work in its sole discretion and at any time may modify Subscription fees for the Subscriptions. Any fee change will become effective at the end of the then-current Billing Cycle.`}</p>
      <p>{`Remote Creative Work will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.`}</p>
      <p>{`Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.`}</p>
      <Text className="heading" variant="h4" mdxType="Text">
  Refunds
      </Text>
      <p>{`There are no refunds for partial months of a job posting. Job postings renew automatically every month, and the purchaser is required to cancel the subscription manually by emailing `}<a parentName="p" {...{
          "href": "mailto:support@remotecreativework.com"
        }}>{`support@remotecreativework.com`}</a>{`.`}</p>
      <p>{`The purchaser is responsibility for all fees and taxes associated with the Service.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Conduct on Website
      </Text>
      <p>{`Your use of the Website is subject to all applicable laws and regulations, and you are solely responsible for the substance of your communications through the Website. By posting information in or otherwise using any communications service, chat room, message board, newsgroup, software library, or other interactive service that may be available to you on or through this Website, you agree that you will not upload, share, post, or otherwise distribute or facilitate distribution of any content — including text, communications, software, images, sounds, data, or other information — that:`}</p>
      <p>{`Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals), or otherwise violates our rules or policies
Victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability
Infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party
Constitutes unauthorized or unsolicited advertising, junk or bulk email (also known as "spamming"), chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling
Contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party
Impersonates any person or entity, including any of our employees or representatives`}</p>
      <p>{`We neither endorse nor assume any liability for the contents of any material uploaded or submitted by third party users of the Website. We generally do not pre-screen, monitor, or edit the content posted by users of communications services, chat rooms, message boards, newsgroups, software libraries, or other interactive services that may be available on or through this Website. However, we and our agents have the right at their sole discretion to remove any content that, in our judgment, does not comply with these Terms of Service and any other rules of user conduct for our site, or is otherwise harmful, objectionable, or inaccurate. We are not responsible for any failure or delay in removing such content. You hereby consent to such removal and waive any claim against us arising out of such removal of content.`}</p>
      <p>{`You agree that we may at any time, and at our sole discretion, terminate your membership, account, or other affiliation with our site without prior notice to you for violating any of the above provisions. In addition, you acknowledge that we will cooperate fully with investigations of violations of systems or network security at other sites, including cooperating with law enforcement authorities in investigating suspected criminal violations.`}</p>
      <Text className="heading" variant="h4" mdxType="Text">
  Third Party Websites
      </Text>
      <p>{`This site may link you to other sites on the Internet or otherwise include references to information, documents, software, materials and/or services provided by other parties. These sites may contain information or material that some people may find inappropriate or offensive.`}</p>
      <p>{`These other sites and parties are not under our control, and you acknowledge that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites, nor are we responsible for errors or omissions in any references to other parties or their products and services. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, or association with, the Website or party by us, or any warranty of any kind, either express or implied.`}</p>
      <Text className="heading" variant="h4" mdxType="Text">
  Intellectual Property
      </Text>
      <p>{`All custom graphics, icons, logos, and service names used on the Website are registered trademarks, service marks, and/or artwork held under copyright of Remote Creative Work or its Affiliates. All other marks are property of their respective owners. Nothing in these Terms of Service grants you any right to use any trademark, service mark, logo, and/or the name or trade names of Remote Creative Work or its Affiliates.`}</p>
      <Text className="heading" variant="h4" mdxType="Text">
  Disclaimer of Warranties
      </Text>
      <p>{`Content available through this Website often represents the opinions and judgments of an information provider, site user, or other person or entity not connected with us. We do not endorse, nor are we responsible for the accuracy or reliability of, any opinion, advice, or statement made by anyone other than an authorized Remote Creative Work spokesperson speaking in their official capacity. Please refer to the specific editorial policies posted on various sections of this Website for further information, which policies are incorporated by reference into these Terms of Service.`}</p>
      <p>{`You understand and agree that temporary interruptions of the services available through this Website may occur as normal events. You further understand and agree that we have no control over third party networks you may access in the course of the use of this Website, and therefore, delays and disruption of other network transmissions are completely beyond our control.`}</p>
      <p>{`You understand and agree that the services available on this Website are provided "AS IS" and that we assume no responsibility for the timeliness, deletion, mis-delivery or failure to store any user communications or personalization settings.`}</p>
      <Text className="heading" variant="h4" mdxType="Text">
  International Use
      </Text>
      <p>{`Although this Website may be accessible worldwide, we make no representation that materials on this Website are appropriate or available for use in locations outside the United States, and accessing them from territories where their contents are illegal is prohibited. Those who choose to access this Website from other locations do so on their own initiative and are responsible for compliance with local laws. Any offer for any product, service, and/or information made in connection with this Website is void where prohibited.`}</p>
      <Text className="heading" variant="h4" mdxType="Text">
  Termination
      </Text>
      <p>{`You agree that we may, in our sole discretion, terminate or suspend your access to all or part of the Website with or without notice and for any reason, including, without limitation, breach of these Terms of Service. Any suspected fraudulent, abusive or illegal activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities.`}</p>
      <p>{`Upon termination or suspension, regardless of the reasons therefore, your right to use the services available on this Website immediately ceases, and you acknowledge and agree that we may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or this Website. We shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection with such termination or suspension.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Governing Law
      </Text>
      <p>{`These Terms of Service and any dispute or claim arising out of, or related to them, shall be governed by and construed in accordance with the internal laws of the US without giving effect to any choice or conflict of law provision or rule.`}</p>
      <p>{`Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Website shall be instituted exclusively in the federal courts of us.`}</p>
    </Paper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      